<template>
    <section class="navbar-config d-middle px-4">
        <div class="col-auto px-0 text-general f-18 f-500">
            {{ title }}
        </div>
    </section>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Undefined title'
        }
    }
}
</script>
<style lang="scss" scoped>
.navbar-config{
    height: 62px;
    border-radius: 12px 12px 0px 0px;
    border-bottom: 1px solid #DBDBDB;
}
</style>